import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Amount from '~/components/Amount';
import Banner from '~/components/Banner';
import Sticky from '~/components/Sticky';
import ListGame from '~/components/ListGame';
import LoadClam from '~/components/LoadClam';
import { requestGetApps } from '~/services/app';
import PaymentMethod from '~/components/PaymentMethod';
import { dispatchCurrentApps } from '~/redux/reducer/app';
import LoginGarena from '~/layouts/components/LoginGarena';
import { requestCheckPromotion } from '~/services/payment';
import LoginAccount from '~/layouts/components/LoginAccount';
import { dispatchStartLoading, dispatchStopLoading } from '~/redux/reducer/module';

function Home() {
    const [apps, setApps] = useState([]);
    const [code, setCode] = useState('');
    const [serial, setSerial] = useState('');
    const [promotion, setPromotion] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPromotion, setCurrentPromotion] = useState(null);

    const [messageErr, setMessageErr] = useState('');
    const [openMessageErr, setOpenMessageErr] = useState(false);

    const app = searchParams.get('app');
    const amount = searchParams.get('amount');
    const channel = searchParams.get('channel');

    const { current_apps } = useSelector((state) => state.app);
    const { banner_url } = useSelector((state) => state.setting);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetch = async () => {
            dispatch(dispatchStartLoading());
            const result = await requestGetApps();

            dispatch(dispatchStopLoading());
            if (result?.status === 200) {
                setApps(result.data);

                if (!app) {
                    const currentApps = result.data[0];
                    dispatch(dispatchCurrentApps(currentApps));
                    setSearchParams({ app: result.data[0].app_code });
                }
            } else {
                localStorage.removeItem('_re');
                window.location.reload();
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnClickPromotion = async () => {
        if (!promotion) {
            setOpenMessageErr(true);
            setMessageErr('Vui lòng điền mã khuyến mãi');
            return;
        }

        const btnPromotion = document.getElementById('btn-promotion');

        btnPromotion.classList.add('relative', 'btn-loading');
        btnPromotion.innerText = '';
        btnPromotion.style.width = '112px';
        btnPromotion.disabled = true;

        const data = {
            code: promotion,
        };

        const result = await requestCheckPromotion(data);

        btnPromotion.classList.remove('relative', 'btn-loading');
        btnPromotion.style.width = 'auto';
        btnPromotion.disabled = false;
        if (result.status === 200) {
            setCurrentPromotion(result.data);
        } else {
            setOpenMessageErr(true);
            setMessageErr(result.error);
            btnPromotion.innerText = 'Áp dụng';
        }
    };

    return (
        <main className="flex flex-1 flex-col dark:bg-theme-dark">
            <div className="mb-5 flex h-full flex-col md:mb-12">
                {banner_url && <Banner banner_url={banner_url} />}

                <div className="bg-[#efefef]">
                    {apps.length > 0 && <ListGame data={apps} />}

                    <div className="rounded-t-[14px] bg-white lg:rounded-none">
                        <div className="mx-auto max-w-5xl p-2 pb-4 lg:px-10 lg:pt-9">
                            {current_apps && <LoadClam apps={current_apps} />}

                            <div className="flex flex-col gap-9 px-2 lg:px-0">
                                {current_apps?.login_type === 'account_id' ? <LoginAccount /> : <LoginGarena />}
                                {current_apps && <PaymentMethod data={current_apps} />}
                                {current_apps && (
                                    <Amount
                                        data={current_apps}
                                        inputCode={code}
                                        setInputCode={setCode}
                                        inputSerial={serial}
                                        setInputSerial={setSerial}
                                        inputPromotion={promotion}
                                        setInputPromotion={setPromotion}
                                        onClickPromotion={handleOnClickPromotion}
                                        promotion={currentPromotion}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {openMessageErr && (
                <div className="fixed inset-0 z-50">
                    <div className="absolute inset-0 bg-black/60"></div>
                    <div className="absolute inset-0 grid overflow-auto justify-items-center items-center">
                        <div className="w-screen p-2">
                            <div className="relative mx-auto flex w-full max-w-80 flex-col gap-5 rounded-lg bg-white p-8 text-center">
                                <div className="flex flex-col gap-3">
                                    <h2 className="text-lg/none font-bold text-text-title">Thông báo</h2>
                                    <p className="text-sm/[22px] text-text-secondary my-3">{messageErr}</p>
                                </div>
                                <button
                                    className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red text-primary-red hover:bg-white-hover bg-white px-5 text-sm font-bold h-10 w-full"
                                    onClick={() => setOpenMessageErr(false)}
                                >
                                    Đóng
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {app && channel && amount && (
                <Sticky data={current_apps} channel={channel} amount={amount} code={code} serial={serial} promotion={currentPromotion} />
            )}
        </main>
    );
}

export default Home;
