import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../components/Footer';
import Header from '../components/Header';
import { dispatchSettings } from '~/redux/reducer/setting';
import { requestGetSettings, requestsCreateRequest } from '~/services/setting';
import { dispatchStartLoading, dispatchStopLoading } from '~/redux/reducer/module';

function DefaultLayout({ children }) {
    const [codeError, setCodeError] = useState('502');
    const [messageError, setMessageError] = useState('Bad Gateway');

    const params = new URL(window.location).searchParams;

    const q = params.get('q');

    const { website_status } = useSelector((state) => state.setting);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const date = localStorage.getItem('_re');

        const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const dateMoment = moment(date);
        const currentDateMoment = moment(currentDate);

        if (currentDateMoment.diff(dateMoment, 'days') >= 1) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else {
            dispatch(dispatchStartLoading());
            const fetch = async () => {
                const result = await requestGetSettings('VN', 'vi');

                dispatch(dispatchStopLoading());
                if (result?.status === 403) {
                    localStorage.removeItem('_re');
                    window.location.reload();
                } else if (result?.status === 200) {
                    dispatch(dispatchSettings(result.data));
                } else {
                    dispatch(dispatchSettings({ banner_url: '', login_id: false, login_garena: true, website_status: false }));
                    setCodeError(500);
                    setMessageError('Máy chủ bảo trì');
                }
            };
            fetch();
        }

        if (!date) {
            const fetch = async () => {
                const result = await requestsCreateRequest(q);

                if (result?.status === 200) {
                    const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    localStorage.setItem('_re', date);
                }
            };
            fetch();
        }

        console.log('%cCopyright © https://thegioicode.com', 'color:#096eff;font-size:16px;font-weight:600');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [q]);

    return (
        <>
            {website_status ? (
                <Fragment>
                    <Header />
                    {children}
                    <Footer />
                </Fragment>
            ) : (
                <div className="default-website">
                    <div className="text code">{codeError}</div>
                    <div className="text message">{messageError}</div>
                </div>
            )}
        </>
    );
}

export default DefaultLayout;
