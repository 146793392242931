import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { convertCurrency } from '~/configs';
import useDeviceDetect from '~/hooks/useDeviceDetect';
import { requestPaymentAccount } from '~/services/payment';
import { dispatchStartLoading, dispatchStopLoading } from '~/redux/reducer/module';

function calculateBonusPrice(originalPrice, bonusPercent = 0) {
    const bonusAmount = (originalPrice * bonusPercent) / 100;

    const bonusPrice = originalPrice + bonusAmount;
    return Math.round(bonusPrice);
}

function calculateDiscountAmount(originalPrice, percent = 0) {
    return (originalPrice * percent) / 100;
}

function Sticky({ data, channel, amount, code, serial, promotion }) {
    const [sticky, setSticky] = useState(null);
    const [msgError, setMsgError] = useState('');
    const [showPayDetail, setShowPayDetail] = useState(false);

    const { isMobile } = useDeviceDetect();
    const params = new URL(window.location).searchParams;

    const appCode = params.get('app');
    const amountCode = params.get('amount');
    const channelCode = params.get('channel');

    const { current_apps } = useSelector((state) => state.app);
    const { auth_account, auth_garena } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { search } = window.location;

    useEffect(() => {
        for (let i = 0; i < data.channels.length; i++) {
            const element = data.channels[i];

            if (element.code === channel) {
                for (let j = 0; j < element.awards.length; j++) {
                    const award = element.awards[j];

                    if (award.amount === Number(amount)) {
                        setSticky(award);
                    }
                }
            }
        }
    }, [data, channel, amount]);

    const handleMouseEnterShowPayDetail = () => {
        if (!isMobile) {
            setShowPayDetail(true);
        }
    };

    const handleMouseLeaveHiddenPayDetail = () => {
        if (!isMobile) {
            setShowPayDetail(false);
        }
    };

    const handleClickShowPayDetail = () => {
        if (isMobile) {
            setShowPayDetail(!showPayDetail);
        }
    };

    const handlePaymentCharging = async () => {
        if (!current_apps) {
            alert('Vui lòng chọn trò chơi để thanh toán');
            return;
        }

        if (current_apps.login_type === 'account_id') {
            if (!auth_account) {
                const inputAccountID = document.getElementById('account_id');
                inputAccountID.scrollIntoView({ behavior: 'instant', block: 'center' });
                inputAccountID.classList.add('form-input-invalid');

                const errorLoginID = document.getElementById('error-login-id');
                errorLoginID.classList.remove('hidden');
                errorLoginID.innerText = 'Vui lòng nhập ID người chơi';
                return;
            }
        }

        if (current_apps.login_type === 'garena') {
            if (!auth_garena) {
                const encodedRedirectURL = encodeURIComponent(search);
                navigate(`/garena-login?redirect=/${encodedRedirectURL}`);
                return;
            }
        }
        if (!code) {
            const inputCode = document.getElementById('code');
            inputCode.scrollIntoView({ behavior: 'instant', block: 'center' });
            inputCode.classList.add('form-input-invalid');

            const errorCode = document.getElementById('code-error-message');
            errorCode.classList.remove('hidden');
            errorCode.innerText = 'Vui lòng nhập mã thẻ cào';
            return;
        }

        if (!serial) {
            const inputSerial = document.getElementById('serial');
            inputSerial.scrollIntoView({ behavior: 'instant', block: 'center' });
            inputSerial.classList.add('form-input-invalid');

            const errorSerial = document.getElementById('serial-error-message');
            errorSerial.classList.remove('hidden');
            errorSerial.innerText = 'Vui lòng nhập mã thẻ cào';
            return;
        }

        dispatch(dispatchStartLoading());
        let data = {
            app: appCode,
            telco: channelCode,
            amount: amountCode,
            code,
            serial,
        };

        if (current_apps.login_type === 'garena') {
            data.username = auth_garena.username;
        } else {
            data.account_id = auth_account.account_id;
        }
        if (promotion) {
            data.promotion = promotion.code;
        }

        const result = await requestPaymentAccount(data);

        dispatch(dispatchStopLoading());
        if (result.error) {
            setMsgError(result.error);
            return;
        } else {
            navigate(`/payment/${result.data}`);
        }
    };

    return (
        <div className="sticky sticky-footer inset-x-0 bottom-0 z-10">
            {msgError && (
                <div className="fixed inset-0 z-50">
                    <div className="absolute inset-0 bg-black/60"></div>
                    <div className="absolute inset-0 grid overflow-auto justify-items-center items-center">
                        <div className="w-screen p-2">
                            <div className="relative mx-auto flex w-full max-w-80 flex-col gap-5 rounded-lg bg-white p-8 text-center">
                                <div className="flex flex-col gap-3">
                                    <h2 className="text-lg/none font-bold text-text-title">Thông báo</h2>
                                    <p className="text-sm/[22px] text-text-secondary my-3">{msgError}</p>
                                </div>
                                <button
                                    className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red text-primary-red hover:bg-white-hover bg-white px-5 text-sm font-bold h-10 w-full"
                                    onClick={() => setMsgError('')}
                                >
                                    Đóng
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <hr className="hidden border-t border-t-[#DFDFDF]/50 md:block" />
            <div className="mx-auto flex w-full max-w-5xl items-center justify-between gap-4 bg-white p-4 shadow-top-line md:justify-end md:gap-10 lg:px-10">
                <div className="flex flex-col md:items-end">
                    <div className="flex items-center gap-1 text-sm/none font-bold md:text-end md:text-base/none">
                        <img className="h-4 w-4 object-contain" src={data.bonus_icon} alt={sticky?.name} />
                        <span>
                            {promotion
                                ? convertCurrency(calculateBonusPrice(sticky?.value + sticky?.bonus, promotion.value))
                                : convertCurrency(sticky?.value + sticky?.bonus)}
                        </span>
                        <button
                            className={`rounded-full bg-[#F2F2F6] text-lg transition-all hover:opacity-70 ${
                                showPayDetail ? 'rotate-180' : 'rotate-180-reverse'
                            }`}
                            onMouseEnter={handleMouseEnterShowPayDetail}
                            onMouseLeave={handleMouseLeaveHiddenPayDetail}
                            onClick={handleClickShowPayDetail}
                        >
                            <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.25 9.96484L9 7.71484L6.75 9.96484"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div className="mt-2 flex items-center gap-1 text-sm/none md:text-end md:text-base/none">
                        <span className="font-medium">Giá:</span>
                        <span className="items-center [text-decoration:inherit] flex font-bold text-primary-red">
                            {convertCurrency(sticky?.amount)}đ
                        </span>
                    </div>

                    {showPayDetail && (
                        <div className="absolute bottom-full flex flex-col gap-3 bg-white p-4 shadow-top-line max-md:inset-x-0 md:w-[390px] md:gap-4 md:rounded-lg md:border md:border-line md:shadow-[0_9px_28px_8px_rgba(0,0,0,0.05)] opacity-100 translate-y-0 fadeIn">
                            <div className="flex justify-between text-sm/none font-bold text-text-title md:text-base/none">
                                Tổng nhận
                                <span className="inline-flex items-center">
                                    <img className="me-1.5 h-4 w-4 object-contain" src={data.bonus_icon} alt={sticky?.name} />
                                    {promotion
                                        ? convertCurrency(calculateBonusPrice(sticky?.value + sticky?.bonus, promotion.value))
                                        : convertCurrency(sticky?.value + sticky?.bonus)}
                                </span>
                            </div>

                            <div>
                                <ul className="flex flex-col gap-3 rounded-[5px] border border-line/50 bg-[#F9F9F9] p-3 text-xs/none md:text-sm/none">
                                    <li className="flex items-center justify-between gap-12">
                                        <div>Giá gốc</div>
                                        <div className="flex shrink-0 items-center gap-1">
                                            <img className="h-3 w-3 object-contain" src={data.bonus_icon} alt={sticky?.name} />
                                            <div className="font-medium">{convertCurrency(sticky?.value)}</div>
                                        </div>
                                    </li>
                                    <li className="flex items-center justify-between gap-12">
                                        <div style={{ color: '#ff7a00' }}>+ Bonus</div>
                                        <div className="flex shrink-0 items-center gap-1">
                                            <img className="h-3 w-3 object-contain" src={data.bonus_icon} alt={sticky?.name} />
                                            <div className="font-medium">{convertCurrency(sticky?.bonus)}</div>
                                        </div>
                                    </li>
                                    {promotion && (
                                        <li className="flex items-center justify-between gap-12">
                                            <div style={{ color: '#ff7a00' }}>+ KM</div>
                                            <div className="flex shrink-0 items-center gap-1">
                                                <img className="h-3 w-3 object-contain" src={data.bonus_icon} alt={sticky?.name} />
                                                <div className="font-medium">
                                                    {convertCurrency(
                                                        calculateDiscountAmount(sticky?.value + sticky?.bonus, promotion.value),
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
                <button
                    className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red bg-primary-red text-white hover:bg-primary-red-hover hover:border-primary-red-hover px-5 text-base font-bold h-11"
                    onClick={handlePaymentCharging}
                >
                    <span className="text-lg h-[18px] w-[18px]">
                        <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M54.125 34.1211C55.2966 32.9495 55.2966 31.05 54.125 29.8784C52.9534 28.7069 51.0539 28.7069 49.8823 29.8784L38.0037 41.7571L32.125 35.8784C30.9534 34.7069 29.0539 34.7069 27.8823 35.8784C26.7108 37.05 26.7108 38.9495 27.8823 40.1211L35.8823 48.1211C37.0539 49.2926 38.9534 49.2926 40.125 48.1211L54.125 34.1211Z"
                                fill="currentColor"
                            ></path>
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M43.4187 3.4715C41.2965 2.28554 38.711 2.28554 36.5889 3.4715L8.07673 19.4055C6.19794 20.4555 4.97252 22.4636 5.02506 24.7075C5.36979 39.43 10.1986 63.724 37.0183 76.9041C38.8951 77.8264 41.1125 77.8264 42.9893 76.9041C69.809 63.724 74.6377 39.43 74.9825 24.7075C75.035 22.4636 73.8096 20.4555 71.9308 19.4055L43.4187 3.4715ZM39.5159 8.7091C39.8191 8.53968 40.1885 8.53968 40.4916 8.7091L68.9826 24.6313C68.6493 38.3453 64.2154 59.7875 40.343 71.5192C40.135 71.6214 39.8725 71.6214 39.6646 71.5192C15.7921 59.7875 11.3583 38.3453 11.025 24.6313L39.5159 8.7091Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </span>
                    Thanh toán
                </button>
            </div>
        </div>
    );
}

export default Sticky;
