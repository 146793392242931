import request from '~/utils';

export const requestPaymentAccount = async (data) => {
    try {
        const res = await request.post('/players/chargingws/v2', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetPaymentByRequestID = async (request_id, command) => {
    try {
        const res = await request.get('/players/chargingws', {
            params: {
                command,
                request: request_id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCheckPromotion = async (data) => {
    try {
        const res = await request.post('/players/promotion', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
