import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { convertCurrency } from '~/configs';
import PaymentResult from '../PaymentResult';
import { requestGetPaymentByRequestID } from '~/services/payment';
import { dispatchStartLoading, dispatchStopLoading } from '~/redux/reducer/module';

function calculateBonusPrice(originalPrice, bonusPercent = 0) {
    const bonusAmount = (originalPrice * bonusPercent) / 100;

    const bonusPrice = originalPrice + bonusAmount;
    return Math.round(bonusPrice);
}

function calculateDiscountAmount(originalPrice, percent = 0) {
    return (originalPrice * percent) / 100;
}

function PagePayment() {
    const [payment, setPayment] = useState(null);
    const [isPayment, setIsPayment] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { request } = useParams();

    const { current_apps } = useSelector((state) => state.app);

    const checkPayment = async () => {
        return await requestGetPaymentByRequestID(request, 'check');
    };

    useEffect(() => {
        if (request) {
            dispatch(dispatchStartLoading());
            const fetch = async () => {
                const result = await requestGetPaymentByRequestID(request, 'get');

                dispatch(dispatchStopLoading());
                if (result.status === 200) {
                    setPayment(result.data);
                } else {
                    navigate('/');
                }

                if (!result.data.approved_at && result.data.confirmed) {
                    const newResult = await checkPayment();

                    if (newResult.status === 200) {
                        setPayment(newResult.data);
                    }
                }
            };
            fetch();
        } else {
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    const handleCheckPayment = async () => {
        setIsPayment(true);
        const result = await checkPayment();

        if (result.status === 200) {
            setPayment(result.data);
        } else {
            alert(result.error);
        }
    };

    return (
        <Fragment>
            {payment && (
                <>
                    {!payment.approved_at || !payment.confirmed ? (
                        <main className="flex flex-1 flex-col dark:bg-theme-dark">
                            <div
                                className="relative h-full bg-[#F9F9F9] bg-fixed bg-top bg-no-repeat"
                                style={{
                                    backgroundImage: "url('https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/FF-06d91604.png')",
                                }}
                            >
                                <div className="flex flex-col bg-white max-md:h-full md:mx-auto md:mb-[52px] md:mt-6 md:max-w-[600px] md:rounded-2xl">
                                    <div className="mb-3 bg-white md:mb-4 md:rounded-t-2xl md:p-2 md:pb-0">
                                        <div className="relative h-20 overflow-hidden md:h-[120px] md:rounded-t-lg">
                                            <div
                                                className="h-full w-full bg-[#BDBDC5] bg-cover bg-center bg-no-repeat rtl:-scale-x-100"
                                                style={{
                                                    backgroundImage: `url('${current_apps.cover_url}')`,
                                                }}
                                            ></div>
                                            <a
                                                className="absolute start-4 top-4 flex items-center gap-0.5 rounded-full bg-black/50 p-1.5 pe-3 text-sm/none font-medium text-white outline outline-[0.5px] -outline-offset-[0.5px] outline-white/50 transition-colors hover:bg-[#606060]/50 md:start-6 md:top-6 md:pe-3.5 md:text-base/none"
                                                href={`/?app=${current_apps.app_code}&channel=${payment.telco}&amount=${payment.declared_value}`}
                                            >
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 80 80"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="rotate-90 text-base rtl:-rotate-90 md:text-xl"
                                                >
                                                    <g>
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M17.1716 28.1716C18.7337 26.6095 21.2663 26.6095 22.8284 28.1716L40 45.3431L57.1716 28.1716C58.7337 26.6095 61.2663 26.6095 62.8284 28.1716C64.3905 29.7337 64.3905 32.2663 62.8284 33.8284L42.8284 53.8284C41.2663 55.3905 38.7337 55.3905 37.1716 53.8284L17.1716 33.8284C15.6095 32.2663 15.6095 29.7337 17.1716 28.1716Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </g>
                                                </svg>
                                                Quay lại
                                            </a>
                                        </div>
                                        <div className="relative mx-5 -mt-9 flex flex-col items-center gap-4 md:-mt-10">
                                            <img
                                                className="block h-[72px] w-[72px] overflow-hidden rounded-lg bg-white object-contain outline outline-4 outline-white md:h-20 md:w-20"
                                                src={current_apps.avatar_url}
                                                alt={current_apps.title}
                                            />
                                            <div className="text-center text-xl/none font-bold text-text-title md:text-2xl/none">
                                                {current_apps.title}
                                            </div>
                                        </div>
                                    </div>
                                    {isPayment || payment.confirmed ? (
                                        <div id="paymentInfoSection" className="">
                                            <div className="bg-[#F9F9F9]">
                                                <div className="mx-auto flex min-h-[250px] w-full flex-col items-center justify-center bg-cover bg-center bg-no-repeat px-6 pb-9 pt-8 text-center md:max-w-[600px] md:bg-contain">
                                                    <div className="flex flex-col items-center text-center">
                                                        <div className="mb-5 inline-block text-[90px]">
                                                            <div className="mb-1">
                                                                <img
                                                                    className="h-9 w-9 animate-spin"
                                                                    src="https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/loading-d72c4881.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 px-4 text-base/none font-bold text-text-title">
                                                            Đang xử lí...
                                                        </div>
                                                        <div className="px-4 text-sm/[22px] text-text-secondary">
                                                            Vui lòng không đóng trình duyệt đến khi trang chuyển hướng
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mb-3 grid grid-cols-2 justify-between gap-x-3.5 px-4 md:mb-4 md:px-10">
                                            <dt className="py-3 text-sm/none md:text-base/none">Tổng nhận</dt>
                                            <dd className="flex items-center justify-end gap-1 py-3 text-end text-sm/none font-medium md:text-base/none">
                                                <img
                                                    className="denomIcon h-3.5 w-3.5"
                                                    src={current_apps.bonus_icon}
                                                    alt={current_apps.title}
                                                />
                                                <span>
                                                    {payment.promotion
                                                        ? convertCurrency(
                                                              calculateBonusPrice(payment.awards + payment.bonus, payment.promotion.value),
                                                          )
                                                        : convertCurrency(payment.awards + payment.bonus)}
                                                </span>
                                            </dd>
                                            <div className="col-span-2 my-1 w-full">
                                                <ul className="flex flex-col gap-3 rounded-[5px] border border-line/50 bg-[#F9F9F9] p-3 text-xs/none md:text-sm/none">
                                                    <li className="flex items-center justify-between gap-12">
                                                        <div>Giá gốc</div>
                                                        <div className="flex shrink-0 items-center gap-1">
                                                            <img
                                                                className="denomIcon h-3 w-3 object-contain"
                                                                src={current_apps.bonus_icon}
                                                                alt={current_apps.title}
                                                            />
                                                            <div className="font-medium">{convertCurrency(payment.awards)}</div>
                                                        </div>
                                                    </li>
                                                    <li className="flex items-center justify-between gap-12">
                                                        <div style={{ color: '#ff7a00' }}>+ Bonus</div>
                                                        <div className="flex shrink-0 items-center gap-1">
                                                            <img
                                                                className="denomIcon h-3 w-3 object-contain"
                                                                src={current_apps.bonus_icon}
                                                                alt={current_apps.title}
                                                            />
                                                            <div className="font-medium">{convertCurrency(payment.bonus)}</div>
                                                        </div>
                                                    </li>
                                                    {payment.promotion && (
                                                        <li className="flex items-center justify-between gap-12">
                                                            <div style={{ color: '#ff7a00' }}>+ KM</div>
                                                            <div className="flex shrink-0 items-center gap-1">
                                                                <img
                                                                    className="denomIcon h-3 w-3 object-contain"
                                                                    src={current_apps.bonus_icon}
                                                                    alt={current_apps.title}
                                                                />
                                                                <div className="font-medium">
                                                                    {convertCurrency(
                                                                        calculateDiscountAmount(
                                                                            payment.awards + payment.bonus,
                                                                            payment.promotion.value,
                                                                        ),
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            <dt className="py-3 text-sm/none md:text-base/none">Mệnh giá</dt>
                                            <dd className="flex items-center justify-end gap-1 py-3 text-end text-sm/none font-medium md:text-base/none">
                                                <span className="items-center [text-decoration:inherit] justify-end flex">
                                                    {convertCurrency(payment.declared_value)}đ
                                                </span>
                                            </dd>
                                            <dt className="py-3 text-sm/none md:text-base/none">Mã thẻ</dt>
                                            <dd className="flex items-center justify-end gap-1 py-3 text-end text-sm/none font-medium md:text-base/none">
                                                <span className="items-center [text-decoration:inherit] justify-end flex">
                                                    {payment.code}
                                                </span>
                                            </dd>
                                            <dt className="py-3 text-sm/none md:text-base/none">Serial</dt>
                                            <dd className="flex items-center justify-end gap-1 py-3 text-end text-sm/none font-medium md:text-base/none">
                                                <span className="items-center [text-decoration:inherit] justify-end flex">
                                                    {payment.serial}
                                                </span>
                                            </dd>
                                            <dt className="py-3 text-sm/none md:text-base/none">Phương thức thanh toán</dt>
                                            <dd className="flex items-center justify-end gap-1 py-3 text-end text-sm/none font-medium md:text-base/none">
                                                {payment.telco}
                                            </dd>
                                            <dt className="py-3 text-sm/none md:text-base/none">Tên người chơi</dt>
                                            <dd className="flex items-center justify-end gap-1 py-3 text-end text-sm/none font-medium md:text-base/none">
                                                {payment.user}
                                            </dd>
                                        </div>
                                    )}
                                    <div className="h-2 bg-[#F9F9F9]"></div>
                                    <div className="flex flex-col gap-6 px-4 pb-8 pt-5 md:p-10 md:pt-6">
                                        <div className="mt-2">
                                            <button
                                                className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red bg-primary-red text-white hover:bg-primary-red-hover hover:border-primary-red-hover px-5 text-base font-bold h-11 w-full"
                                                disabled={isPayment || payment.confirmed}
                                                onClick={handleCheckPayment}
                                            >
                                                {isPayment || payment.confirmed ? 'Đang thanh toán...' : 'Thanh toán'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    ) : (
                        <PaymentResult payment={payment} app={current_apps} />
                    )}
                </>
            )}
        </Fragment>
    );
}

export default PagePayment;
