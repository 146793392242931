import moment from 'moment';
import { Link } from 'react-router-dom';

import { convertCurrency } from '~/configs';
import IconPayFail from '~/assets/icon/IconPayFail';
import IconPaySuccess from '~/assets/icon/IconPaySuccess';

function calculateBonusPrice(originalPrice, bonusPercent = 0) {
    const bonusAmount = (originalPrice * bonusPercent) / 100;

    const bonusPrice = originalPrice + bonusAmount;
    return Math.round(bonusPrice);
}

function calculateDiscountAmount(originalPrice, percent = 0) {
    return (originalPrice * percent) / 100;
}

function PaymentResult({ payment, app }) {
    return (
        <main className="flex flex-1 flex-col dark:bg-theme-dark">
            <div className="bg-[#F9F9F9]">
                <div className="mx-auto flex min-h-[250px] w-full flex-col items-center justify-center bg-cover bg-center bg-no-repeat px-6 pb-9 pt-8 text-center md:max-w-[600px] md:bg-contain">
                    {payment.status === 1 ? <IconPaySuccess /> : <IconPayFail />}
                    <div className="mb-2 text-xl/none font-bold text-text-title">
                        {payment.status === 1 ? 'Thanh toán thành công' : 'Thanh toán thất bại'}
                    </div>
                    <div className="text-sm/[22px]">
                        ID giao dịch <br />
                        {payment.trans_id}
                    </div>
                </div>
            </div>
            <div className="mx-auto flex w-full grow flex-col gap-y-4 p-4 md:max-w-[600px]">
                <div className="grid grid-cols-2 gap-x-3.5">
                    <dt className="py-3 text-sm/none">Tổng nhận</dt>
                    <dd className="break-words py-3 text-end text-sm/none font-medium">
                        <div className="flex items-center justify-end">
                            <img className="denomIcon me-1.5 h-3 w-3" src={app.bonus_icon} alt={app.title} />
                            <span id="totalPrice">
                                {payment.promotion
                                    ? convertCurrency(calculateBonusPrice(payment.awards + payment.bonus, payment.promotion.value))
                                    : convertCurrency(payment.awards + payment.bonus)}
                            </span>
                        </div>
                    </dd>
                    <div id="price-info" className="col-span-2 my-1 w-full">
                        <ul className="flex flex-col gap-3 rounded-[5px] border border-line/50 bg-[#F9F9F9] p-3 text-xs/none md:text-sm/none">
                            <li className="flex items-center justify-between gap-12">
                                <div>Gốc</div>
                                <div className="flex shrink-0 items-center gap-1">
                                    <img className="denomIcon h-3 w-3 object-contain" src={app.bonus_icon} alt={app.title} />
                                    <div id="originalPrice" className="font-medium">
                                        {convertCurrency(payment.awards)}
                                    </div>
                                </div>
                            </li>
                            <li className="flex items-center justify-between gap-12">
                                <div style={{ color: '#ff7a00' }}>+ Bonus</div>
                                <div className="flex shrink-0 items-center gap-1">
                                    <img className="denomIcon h-3 w-3 object-contain" src={app.bonus_icon} alt={app.title} />
                                    <div id="bonusPrice" className="font-medium">
                                        {convertCurrency(payment.bonus)}
                                    </div>
                                </div>
                            </li>
                            {payment.promotion && (
                                <li className="flex items-center justify-between gap-12">
                                    <div style={{ color: '#ff7a00' }}>+ KM</div>
                                    <div className="flex shrink-0 items-center gap-1">
                                        <img className="denomIcon h-3 w-3 object-contain" src={app.bonus_icon} alt={app.title} />
                                        <div className="font-medium">
                                            {convertCurrency(
                                                calculateDiscountAmount(payment.awards + payment.bonus, payment.promotion.value),
                                            )}
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <dt className="py-3 text-sm/none">Giá</dt>
                    <dd className="break-words py-3 text-end text-sm/none font-medium">
                        <span id="denomPrice" className="items-center [text-decoration:inherit] justify-end flex">
                            {convertCurrency(payment.declared_value)}đ
                        </span>
                    </dd>
                    <dt className="py-3 text-sm/none">Game</dt>
                    <dd id="gameName" className="break-words py-3 text-end text-sm/none font-medium">
                        {app.title}
                    </dd>
                    <dt className="py-3 text-sm/none">Phương thức thanh toán</dt>
                    <dd id="paymentMethod" className="break-words py-3 text-end text-sm/none font-medium">
                        {payment.telco}
                    </dd>
                    <dt className="py-3 text-sm/none">Thời gian giao dịch</dt>
                    <dd id="transactionTime" className="break-words py-3 text-end text-sm/none font-medium">
                        {moment(payment.created_at).format('HH:mm:ss DD/MM/YYYY')}
                    </dd>
                </div>
                <Link
                    to="/"
                    className="mt-4 inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red bg-primary-red text-white hover:bg-primary-red-hover hover:border-primary-red-hover px-5 text-base font-bold h-11 w-full"
                >
                    Quay về trang chủ
                </Link>
            </div>
        </main>
    );
}

export default PaymentResult;
