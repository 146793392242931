import request from '~/utils';

export const requestGetApps = async () => {
    try {
        const res = await request.get('/players/channels');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
export const requestGetBillings = async (app, channel, amount) => {
    try {
        const res = await request.get('/players/billings', {
            params: {
                app,
                channel,
                amount,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
