import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { convertCurrency } from '~/configs';

function Amount({
    data,
    inputCode,
    setInputCode,
    inputSerial,
    setInputSerial,
    inputPromotion,
    setInputPromotion,
    onClickPromotion,
    promotion,
}) {
    const [amounts, setAmounts] = useState([]);
    const [checked, setChecked] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [channelChecked, setChannelChecked] = useState(null);

    const app = searchParams.get('app');
    const channel = searchParams.get('channel');
    const amountCode = searchParams.get('amount');

    const { promotion_active } = useSelector((state) => state.setting);

    useEffect(() => {
        if (app && channel) {
            for (let i = 0; i < data.channels.length; i++) {
                const channels = data.channels[i];

                if (channels.code === channel) {
                    setChannelChecked(channels);
                    setAmounts(channels.awards);
                }
            }
        }
    }, [data, channel, app]);

    const handleSelectAmount = (amount, index) => {
        setChecked(index);
        setSearchParams({ app, channel, amount: amount.amount });
    };

    return (
        <div className="flex flex-col">
            <div className="mb-3 flex scroll-mt-16 items-center gap-2 text-lg/none font-bold text-text-title md:text-xl/none">
                <div className="grid items-center">
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="col-start-1 row-start-1 text-2xl text-primary-red"
                    >
                        <path
                            d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15.7574C24 16.553 23.6839 17.3161 23.1213 17.8787L17.8787 23.1213C17.3161 23.6839 16.553 24 15.7574 24H3C1.34315 24 0 22.6569 0 21V3Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <div className="col-start-1 row-start-1 text-center text-base/none text-white">3</div>
                </div>
                Chọn mệnh giá
            </div>

            <div className="flex flex-col gap-4">
                <div className="flex flex-col empty:hidden">
                    {channel ? (
                        <div className="flex flex-col gap-4 empty:hidden">
                            <div className="grid grid-cols-3 gap-2.5 sm:grid-cols-4 md:grid-cols-6 md:gap-4 empty:hidden">
                                {amounts.map((amount, index) => (
                                    <div
                                        className={`denomItem relative ${!app || !channel ? 'disabled' : ''}`}
                                        key={index}
                                        data-headlessui-state={index === checked || amount.amount === Number(amountCode) ? 'checked' : ''}
                                        onClick={() => handleSelectAmount(amount, index)}
                                    >
                                        <div className="peer relative flex min-h-[50px] cursor-pointer flex-col items-center justify-center rounded-md outline outline-1 -outline-offset-1 outline-box-border ui-checked:bg-selected-bg ui-checked:outline-2 ui-checked:-outline-offset-2 ui-checked:outline-primary-red ui-disabled:bg-[#F4F4F4] ui-disabled:outline-none sm:min-h-[64px] md:min-h-[72px]">
                                            <div className="flex items-center">
                                                <span className="denomPrice text-sm/none font-medium md:text-lg/none">
                                                    {convertCurrency(amount.amount)}đ
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="text-center my-5">Vui lòng chọn loại thẻ thanh toán</div>
                    )}
                </div>
            </div>

            {/* Nhập thông tin thẻ */}
            <div className="mb-3 mt-9 flex scroll-mt-16 items-center gap-2 text-lg/none font-bold text-text-title md:text-xl/none">
                <div className="grid items-center">
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="col-start-1 row-start-1 text-2xl text-primary-red"
                    >
                        <path
                            d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15.7574C24 16.553 23.6839 17.3161 23.1213 17.8787L17.8787 23.1213C17.3161 23.6839 16.553 24 15.7574 24H3C1.34315 24 0 22.6569 0 21V3Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <div className="col-start-1 row-start-1 text-center text-base/none text-white">4</div>
                </div>
                Nhập thông tin thẻ
            </div>

            <div className="grid gap-6 md:grid-cols-2 md:gap-x-[30px] md:rounded-[5px] md:border md:border-line md:p-[30px] md:mt-4">
                <div>
                    <img
                        className={`h-[50px] w-[50px] object-contain ${channel ? '' : 'hidden'}`}
                        src={channelChecked?.icon_url}
                        alt={channelChecked?.name}
                    />
                    <div className="mb-2 text-xs/normal text-text-secondary md:mb-4">Mỗi tài khoản có thể nhận ưu đãi 1 lần</div>
                    <div className="flex flex-col gap-6">
                        <div className="codeWrapper flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <span className="text-[15px]/4 font-medium text-text-title">Mã thẻ</span>
                            </div>
                            <div className="relative flex-col items-start justify-between">
                                <input
                                    name="code"
                                    id="code"
                                    placeholder="Nhập mã thẻ"
                                    className="form-input w-full flex-1"
                                    value={inputCode}
                                    onChange={(e) => {
                                        setInputCode(e.target.value);
                                        document.getElementById('code').classList.remove('form-input-invalid');
                                        const errorCode = document.getElementById('code-error-message');
                                        errorCode.classList.add('hidden');
                                        errorCode.innerText = '';
                                    }}
                                />
                            </div>
                            <div id="code-error-message" className="text-xs text-primary-red md:text-sm hidden"></div>
                        </div>

                        <div className="serialWrapper flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <span className="text-[15px]/4 font-medium text-text-title">Số serial</span>
                            </div>
                            <div className="relative flex-col items-start justify-between">
                                <input
                                    name="serial"
                                    id="serial"
                                    placeholder="Nhập serial"
                                    className="form-input w-full flex-1"
                                    value={inputSerial}
                                    onChange={(e) => {
                                        setInputSerial(e.target.value);
                                        document.getElementById('serial').classList.remove('form-input-invalid');
                                        const errorSerial = document.getElementById('serial-error-message');
                                        errorSerial.classList.add('hidden');
                                        errorSerial.innerText = '';
                                    }}
                                />
                            </div>
                            <div id="serial-error-message" className="text-xs text-primary-red md:text-sm hidden"></div>
                        </div>

                        {promotion_active && (
                            <div className="serialWrapper flex flex-col gap-2">
                                <div className="flex items-center gap-1">
                                    <span className="text-[15px]/4 font-medium text-text-title">Mã khuyễn mãi</span>
                                </div>
                                <div className="flex items-start justify-between" style={{ gap: 4 }}>
                                    <input
                                        name="promotion"
                                        id="promotion"
                                        placeholder="Nhập mã khuyễn mãi"
                                        className="form-input w-full flex-1"
                                        value={inputPromotion}
                                        disabled={promotion}
                                        onChange={(e) => {
                                            setInputPromotion(e.target.value);

                                            document.getElementById('promotion').classList.remove('form-input-invalid');
                                            const errorPromotion = document.getElementById('promotion-error-message');
                                            errorPromotion.classList.add('hidden');
                                            errorPromotion.innerText = '';
                                        }}
                                    />
                                    <button
                                        id="btn-promotion"
                                        className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red bg-primary-red text-white hover:bg-primary-red-hover hover:border-primary-red-hover px-5 text-base font-bold h-11"
                                        onClick={onClickPromotion}
                                        disabled={promotion}
                                    >
                                        {promotion ? `${promotion.value}%` : 'Áp dụng'}
                                    </button>
                                </div>
                                <div id="promotion-error-message" className="text-xs text-primary-red md:text-sm hidden"></div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="md:flex md:flex-col md:gap-2 md:border-none md:bg-transparent md:py-0 rounded-md border border-line/50 bg-[#F9F9F9] py-1">
                    {channel ? (
                        amounts.map((amount, index) => (
                            <div className="flex justify-between gap-4 px-5 py-4 items-center" key={index}>
                                <div className="flex gap-3 items-center">
                                    <img className="h-[30px] w-[30px] object-contain" src={data?.bonus_icon} alt={amount.name} />
                                    <div className="flex flex-col gap-1.5">
                                        <p className="text-sm/none font-medium md:text-base/none">
                                            {convertCurrency(amount.value)} {amount.name}
                                        </p>
                                        <div className="flex flex-col gap-1.5 text-xs/none font-medium text-bonus empty:hidden md:text-sm/none">
                                            {' '}
                                            + {amount.name} × {convertCurrency(amount.bonus)}
                                        </div>
                                    </div>
                                </div>
                                <div className="shrink-0 py-2 text-sm/none font-medium">
                                    <span className="items-center [text-decoration:inherit] justify-end flex">
                                        {convertCurrency(amount.amount)}đ
                                    </span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center mt-10">Chọn loại thẻ để xem bảng giá</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Amount;
